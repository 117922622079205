<template>
                    <div class="card">
                        <div class="card-body">
                            <form @submit.prevent="createPost" method="post" id="form_event_edit" class="form_event_edit">
                                <div class="row">
                                    <div class="col-md-3">
                                    </div>
                                    <div class="col-md-6">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <label for="unit_kerja">Unit Kerja yang dilaporkan</label>
                                                <input v-model="formData.unit_kerja" type="text" class="form-control" id="unit_kerja" name="unit_kerja"
                                                    placeholder="Unit kerja yang akan anda laporkan" required>
                                                <div class="valid-feedback">
                                                    <i class="bx bx-radio-circle"></i>
                                                    Valid
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <label for="report_name">Reporter Name</label>
                                                <input v-model="formData.report_name" type="text" class="form-control" id="report_name" name="report_name"
                                                    placeholder="Your Name" required>
                                                <div class="valid-feedback">
                                                    <i class="bx bx-radio-circle"></i>
                                                    Valid
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <label for="report">Report</label>
                                                <textarea v-model="formData.report" class="form-control" id="client" name="client" placeholder="Deskripsi Laporan"
                                                    required />
                                                <div class="valid-feedback">
                                                    <i class="bx bx-radio-circle"></i>
                                                    Valid
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                    </div>
                                    
                                </div>

                                <button type="submit" class="btn btn-primary ml-1">
                                    <i class="bx bx-check d-block d-sm-none"></i>
                                    <span class="d-none d-sm-block">Submit</span>
                                </button>
                            </form>
                        </div>
                    </div>
</template>

<script>
    import axios from 'axios'
    import Swal from 'sweetalert2'
    export default {
        name: 'API',
        data() {
            return {
                formData :{
                    report: "",
                    report_name: "",
                    unit_kerja: "",
                }
                // posts: [],
            }
        },
        methods: {
            createPost() {
                axios
                // .post('http://127.0.0.1:8000/api/report/insert', this.formData)
                .request({
                    url: 'http://127.0.0.1:8000/api/report/insert',
                    method: 'post',
                    data: this.formData,
                    // baseURL: 'https://preview-deliver.kenticocloud.com/PROJECT_ID',
                    headers: {
                        'Authorization': 'Bearer 2|C6pY3DKPIkIEtdeMV2TgUj0NE73y2ZXH4KZ8cp1Z',
                        'Accept': 'application/json',
                    }
                })
                .then((response) => {
                    console.log(response);
                    Swal.fire('Success !!!');
                })
                .then((error) => console.log(error))
            }
        }


    }
</script>

<style scoped>

</style>